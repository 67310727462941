<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h1>Ergo equipment guide<br />
																				Accessories</h1>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'mobile-devices-a45' }">
																						<em>Up
																						Next: </em> Mobile Devices <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37" />
																				</router-link>
																			</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_raw_code wpb_content_element wpb_raw_html">
													<div class="wpb_wrapper">
														<div class="equipment_image_wrapper">
															<img src="../../assets/images/airbnb/Airbnb-Equipment-Main-Page.jpg"
																alt="Equipment Guides" />
															<router-link :to="{ name: 'mobile-devices-a45' }" id="equipment-icon-mobile-devices" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Mobile devices" />
															</router-link>

															<router-link :to="{ name: 'screens-a45' }" id="equipment-icon-screens" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Screens" />
															</router-link>

															<router-link :to="{ name: 'keyboard-mouse-eq-a45' }" id="equipment-icon-keyboard-mouse" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Keyboard and Mouse" />
															</router-link>

															<router-link :to="{ name: 'chairs-a45' }" id="equipment-icon-chairs" class="equipment-icon flipped">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Chairs" />
															</router-link>			

															<router-link :to="{ name: 'lighting-a45' }" id="equipment-icon-lighting" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Lighting" />
															</router-link>

															<router-link :to="{ name: 'ergo-accessories-a45' }" id="equipment-icon-ergo-accessories" class="equipment-icon flipped current">
															<img
																	src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png"
																	alt="Ergo accessories" />
															</router-link>

															<div class="equipment-desc">
																<h3>Accessories</h3>
																<ul>
																	<li>Keep accessories close to you</li>
																	<li>Stands are great for screens</li>
																	<li>Footrests create stability</li>
																	<li>Accessories can be store bought or D.I.Y</li>
																</ul>
															</div>

														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner "
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-8">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Ergo is simpler than you think.</h2>
																			<p>Ergonomics, simply put, is being smart
																				about how you work. It’s about
																				empowering yourself to take control of
																				your environment. And the good news is,
																				a little often goes a long way.</p>
																			<p>So, don’t forget about the little things
																				like foot rests, back cushions, monitor
																				risers, phone stands, speaker
																				microphones and blue light glasses.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="700" height="467"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Accessories-1.jpg"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment-Accessories" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div
															class="wpb_column vc_column_container vc_col-sm-3 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="22" height="50"
																					src="../../assets/images/airbnb/Airbnb-equipment-Accessories-Set-up.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---equipment---Accessories---Set-up" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637041795476">
																		<div class="wpb_wrapper">
																			<h4>Set-up</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>If your monitor or screen is too
																					low, risers work wonders.</li>
																				<li>Are your feet dangling? Fill the gap
																					with a foot rest.</li>
																				<li>If your back is strained, a back
																					support will do the trick.</li>
																				<li>Sitting too low? Try a seat cushion
																					for more height.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-9 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="64" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Accessories-DIY.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Accessories---DIY" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637041823283">
																		<div class="wpb_wrapper">
																			<h4>Ergo savers and D.I.Y. ideas</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li><strong>Ironing boards.</strong>
																					Need a height adjustable surface?
																					Look no further than your closet.
																					Your ironing board might be your new
																					desk.</li>
																				<li><strong>Pillows and towels.</strong>
																					If you need back support or extra
																					cushion on your chair, pillows and
																					towels work great.</li>
																				<li><strong>Books and boxes.</strong> If
																					you need to raise your screen or put
																					your feet on a foot rest, books and
																					boxes will serve you well.</li>
																				<li><strong>Computer / Blue Light
																						Glasses.</strong> If your eyes
																					are straining, talk to your eye care
																					professional about computer or blue
																					light glasses.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>All equipment guides</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Screens-Main-Page.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Equipment---Screens---Main-Page" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45'}">
																			<h4>Screens</h4>
																			<p>Wanna have great posture and be
																				productive? Learn how.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="600"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-Mouse.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Keyboard-Mouse" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45'}">
																			<h4>Keyboard &amp; mouse</h4>
																			<p>Discover the best technique to mouse and
																				type.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Chairs.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Chairs" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45'}">
																			<h4>Chairs</h4>
																			<p>Ergo has your back no matter where you
																				sit.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipmnet-Lighting.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipmnet---Lighting" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45'}">
																			<h4>Lighting</h4>
																			<p>Learn how to use light to improve your
																				output.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Accessories.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment-Accessories" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45'}">
																			<h4>Ergo accessories</h4>
																			<p>It’s all in the details. Learn how
																				accessories help.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'mobile-devices-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Mobile-Devices.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Mobile-Devices" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'mobile-devices-a45'}">
																			<h4>Mobile devices</h4>
																			<p>Ergo includes your mobile devices. Learn
																				more.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
			

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'ergo-accessories-a45',
	}
</script>